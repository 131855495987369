// 
// user.scss
// Use this to write your custom SCSS
//

// Complete list of default bootstrap css. Use this as a reference to override.
// https://github.com/twbs/bootstrap/blob/main/dist/css/bootstrap.css


// Override the btn-primary
$primary-dark: #564CAF;
$primary: #6658EA;
$primary-light: #687DED;

.btn-primary {
    color: #fff;
    background-color: $primary;
    border-color: $primary;
}

.btn-primary:hover {
    color: #fff;
    background-color: $primary-dark;
    border-color: $primary-dark;
}
  
.btn-check:focus + .btn-primary, .btn-primary:focus {
    color: #fff;
    background-color: $primary-dark;
    border-color: $primary-dark;
    box-shadow: 0 0 0 0.25rem rgba(104, 125, 237, 1);
}

.btn-check:checked + .btn-primary,
.btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: $primary-dark;
    border-color: $primary-dark;
}

.btn-check:checked + .btn-primary:focus,
.btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus,
.show > .btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.25rem rgba(104, 125, 237, 1);
}

.btn-primary:disabled, .btn-primary.disabled {
    color: #fff;
    background-color: $primary-light;
    border-color: $primary-light;
}

// Buy and sell buttons on topbar
.sell-buy-buttons-topbar {
    display: flex;
    align-items: center;
}

.bgcolor-primary {
    background-color: $primary;
}